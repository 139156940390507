import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Space, Switch } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import QRCodeStyling from 'qr-code-styling'

import Button from 'src/components/Button'
import logo from 'src/assets/img/logo.svg'

const qrCode = new QRCodeStyling({
  type: 'svg',
  imageOptions: {
    margin: 8
  }
})

const QRModal = props => {
  const { isVisible, url, closeModal } = props
  const [isWithLogo, setIsWithLogo] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    qrCode.append(ref.current)
    qrCode.update({ data: `${url}?qr` })
  }, [url])

  const downloadQR = useCallback(() => {
    qrCode.download({ extension: 'svg' })
  }, [])

  const onQRStyleChange = useCallback(checked => {
    qrCode.update({ image: checked ? logo : null })
    setIsWithLogo(checked)
  }, [])

  const onClose = useCallback(() => {
    qrCode.update({ image: null })
    setIsWithLogo(false)
    closeModal()
  }, [closeModal])

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width={350}
      bodyStyle={{ textAlign: 'center', paddingTop: 48, display: 'grid', gap: 8 }}>
      <div ref={ref} />
      <Space style={{ justifyContent: 'center' }}>
        <FormattedMessage id={'qr.withoutLogo'} />
        <Switch checked={isWithLogo} onChange={onQRStyleChange} />
        <FormattedMessage id={'qr.withLogo'} />
      </Space>
      <Button icon={<DownloadOutlined />} onClick={downloadQR}>
        <FormattedMessage id={'home.button.downloadQR'} />
      </Button>
    </Modal>
  )
}

export default QRModal
