import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { Username } from './styled'

import { getShortName } from '../../common/utils'

const User = props => {
  const { user } = props
  return (
    <div>
      <Avatar size={'small'} icon={<UserOutlined />} />
      <Username>
        {getShortName(user.name, user.surname)}
      </Username>
    </div>
  )
}
User.propTypes = {
  user: PropTypes.object,
}
export default User
