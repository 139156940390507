const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `http://ping.test.mylm.it/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.test.mylm.it'
  },
  test: {
    LOGIN_URL: `http://ping.test.mylm.it/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.test.mylm.it'
  },
  prod: {
    LOGIN_URL: `http://ping.mylm.it/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.mylm.it'
  },
}

export default envConfig[env]