import styled from 'styled-components'
import { Layout } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 32px;
  margin: 0 auto;
  min-height: calc(100vh - 112px);
`

export const ActionsRow = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 50% 1fr auto;
  padding: 16px 0;
`