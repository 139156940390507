import { VALIDATE_TOKEN } from '../action-types'
import api, { BE_API } from '../../common/api'
import { handleServerError } from '../../common/utils'

export const validateToken = token => async dispatch => {
  try {
    const res = await api.users.authenticate(token)
    const user = { ...res.data.user, token }
    BE_API.defaults.headers.Authorization = `Bearer ${token}`
    return dispatch({ type: VALIDATE_TOKEN, payload: user })
  } catch (error) {
    return handleServerError(error)
  }
}