import { useCallback, useEffect, useMemo } from 'react'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { DatePicker, Form, Input, Modal, Space } from 'antd'
import { Collapse, FormItem } from './styled'

const ModalForm = props => {
  const { intl, isLoading, isVisible, row, hasHashError, closeModal, submitForm } = props
  const mode = useMemo(() => (isEmpty(row) ? 'new' : 'edit'), [row])
  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      isEmpty(row) ? form.resetFields() : form.setFieldsValue(row)
    }
  }, [form, isVisible, row])

  const onClose = useCallback(() => {
    if (!form) return
    form.resetFields()
    closeModal()
  }, [form, closeModal])

  const onOk = useCallback(async () => {
    if (!form) return
    const values = await form.validateFields()
    submitForm({ ...row, ...values })
  }, [form, row, submitForm])

  return (
    <Modal
      visible={isVisible}
      title={intl.formatMessage({ id: `editModal.title.${mode}` })}
      okText={intl.formatMessage({ id: `editModal.okText.${mode}` })}
      cancelText={intl.formatMessage({ id: 'editModal.cancelText' })}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      onCancel={onClose}
      onOk={onOk}>
      <Form form={form} initialValues={row} key={row.id} layout={'vertical'}>
        <FormItem
          name={'longUrl'}
          label={intl.formatMessage({ id: 'form.label.longUrl' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'form.validation.longUrl' })
            }
          ]}>
          <Input />
        </FormItem>
        <FormItem
          name={'description'}
          label={intl.formatMessage({ id: 'form.label.description' })}>
          <Input showCount maxLength={30} />
        </FormItem>
        <Collapse ghost>
          <Collapse.Panel
            header={<h3><FormattedMessage id={'form.title.advancedOptions'} /></h3>}
          >
            <FormItem
              name={'hash'}
              label={intl.formatMessage({ id: 'form.label.hash' })}
              validateStatus={hasHashError && 'error'}
              hasFeedback={hasHashError}>
              <Input disabled={mode === 'edit'} />
            </FormItem>
            <Space>
              <FormItem
                name={'validFrom'}
                label={intl.formatMessage({ id: 'form.label.validFrom' })}>
                <DatePicker />
              </FormItem>
              <FormItem
                name={'expireAt'}
                label={intl.formatMessage({ id: 'form.label.expireAt' })}>
                <DatePicker />
              </FormItem>
            </Space>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Modal>
  )
}

export default injectIntl(ModalForm)
