const Link = props => {
  const { children, className, href } = props
  return (
    <a
      href={href}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={className}
      style={{ color: 'inherit' }}>
      {children}
    </a>
  )
}

export default Link
