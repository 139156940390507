export const linkTypesColors = {
  PHONE: 'purple',
  SOCIAL: 'blue',
  VIDEO: 'red',
  LMIT: 'green',
  LMFR: 'green',
  LMPL: 'green',
  SEARCH: 'cyan',
  APP: 'green',
  DRIVE: 'cyan',
  DOCS: 'blue',
  MEDIA: 'gold',
  SITES: 'geekblue',
  REDIRECT: 'default',
  LMIT_PRODUCT: 'green',
  LMIT_SERVICE: 'green',
  LMIT_SALE: 'green',
  LMIT_STORE: 'green',
  SOURCECODE: 'blue',
  APPSTORE: 'blue',
  ERROR: '#FF0000',
  UNKNOWN: 'default',
}