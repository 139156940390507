import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Input, message, Space, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'
import {
  getLinks,
  createLink,
  updateLink,
  deleteLink
} from 'src/redux-store/actions/app-actions'

import LinksTable from 'src/components/LinksTable'
import Button from 'src/components/Button'
import ModalForm from 'src/components/ModalForm'
import QRModal from 'src/components/QRModal'
import { ActionsRow } from 'src/common/styled'

import { stringDateToMoment } from 'src/common/utils'

const Home = props => {
  const {
    intl,
    user,
    getLinksAction,
    createLink,
    updateLink,
    deleteLinkAction
  } = props
  const [isLoading, setIsLoading] = useState(false)

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isQRModalVisible, setIsQRModalVisible] = useState(false)

  const [isAdminView, setIsAdminView] = useState(false)
  const [currentRow, setCurrentRow] = useState({})
  const [hasHashError, setHasHashError] = useState(false)

  const [links, setLinks] = useState([])
  const [filteredLinks, setFilteredLinks] = useState(
    links.filter(l => l.username === user.ldap)
  )

  const getLinks = useCallback(
    async q => {
      setIsLoading(true)
      const links = await getLinksAction(q)
      setLinks(links)
      setIsLoading(false)
    },
    [getLinksAction]
  )

  useEffect(() => {
    getLinks()
  }, [getLinks])

  useEffect(() => {
    if (isAdminView) setFilteredLinks(links)
    if (!isAdminView)
      setFilteredLinks(links.filter(l => l.username === user.ldap))
  }, [isAdminView, links, user.ldap])

  const openEditModal = useCallback(row => {
    setIsEditModalVisible(true)

    const currentRow = isEmpty(row)
      ? row
      : {
        ...row,
        validFrom: row.validFrom && stringDateToMoment(row.validFrom),
        expireAt: row.expireAt && stringDateToMoment(row.expireAt)
      }
    setCurrentRow(currentRow)
  }, [])

  const closeEditModal = useCallback(() => {
    setIsEditModalVisible(false)
    setHasHashError()
    setCurrentRow({})
  }, [])

  const openQRModal = useCallback(row => {
    setCurrentRow(row)
    setIsQRModalVisible(true)
  }, [])

  const closeQRModal = useCallback(() => setIsQRModalVisible(false), [])

  const searchLink = useCallback(value => getLinks(value), [getLinks])

  const upsertLink = useCallback(
    async row => {
      setIsLoading(true)
      const res = row.id ? await updateLink(row) : await createLink(row)

      if (res.status === 406) {
        setIsLoading(false)
        setHasHashError(true)
        return message.error(
          intl.formatMessage({ id: 'message.error.duplicateAlias' })
        )
      }

      getLinks()
      closeEditModal()
      message.success(
        intl.formatMessage({
          id: `message.success.${row.id ? 'updatedLink' : 'createdLink'}`
        })
      )
    },
    [intl, createLink, updateLink, getLinks, closeEditModal]
  )

  const deleteLink = useCallback(
    async id => {
      setIsLoading(true)
      const res = await deleteLinkAction(id)

      if (res.status === 200) {
        getLinks()
        message.success(
          intl.formatMessage({ id: 'message.success.deletedLink' })
        )
      }
    },
    [intl, deleteLinkAction, getLinks]
  )

  return (
    <>
      <h1>
        <FormattedMessage id={'home.title'} />
      </h1>
      <ActionsRow>
        <Input.Search
          placeholder={intl.formatMessage({ id: 'home.placeholder.searchLink' })}
          allowClear
          onSearch={searchLink}
        />
        <Space>
          <Switch checked={isAdminView} onChange={setIsAdminView} />
          <FormattedMessage id={'home.switch.adminView'} />
        </Space>
        <Button
          icon={<PlusOutlined />}
          onClick={() => openEditModal({})}>
          <FormattedMessage id={'home.button.newLink'} />
        </Button>
      </ActionsRow>
      {LinksTable({
        isLoading,
        data: filteredLinks,
        isAdminView,
        ldap: user.ldap,
        openQRModal,
        openEditModal,
        deleteLink
      })}
      {ModalForm({
        isLoading,
        isVisible: isEditModalVisible,
        row: currentRow,
        hasHashError,
        closeModal: closeEditModal,
        submitForm: upsertLink
      })}
      {QRModal({
        isVisible: isQRModalVisible,
        url: currentRow.shortUrl,
        closeModal: closeQRModal
      })}
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user.current
})
const mapDispatchToProps = {
  getLinksAction: getLinks,
  createLink,
  updateLink,
  deleteLinkAction: deleteLink
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
