import { Form, Collapse as AntCollapse } from 'antd'
import styled from 'styled-components'

export const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
  &:last-child {
    margin: 0;
  }
`
export const Collapse = styled(AntCollapse)`
  &> .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse-content-box {
    padding: 16px 0 0;
  }
`