import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {}
})

const apis = {
  users: {
    authenticate: token => BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  links: {
    list: q => BE_API.get('/links/list', { params: { q } }),
    create: body => BE_API.post('/links/create', body),
    update: (id, body) => BE_API.put(`/links/update/${id}`, body),
    delete: id => BE_API.delete(`/links/delete/${id}`)
  }
}
export default apis