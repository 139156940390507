import { UppercaseButton } from './styled'

const Button = props => {
  const { children, type } = props
  return (
    <UppercaseButton type={type || 'primary'} {...props}>
      <span>{children}</span>
    </UppercaseButton>
  )
}
export default Button
