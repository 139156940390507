import { useCallback, useMemo } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Empty, message, Modal, Space, Table, Tag, Tooltip } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  QrcodeOutlined
} from '@ant-design/icons'

import Loader from 'src/components/Loader'
import Link from 'src/components/Link'

import { linkTypesColors } from 'src/common/constants'

const LinksTable = props => {
  const { intl, isLoading, data, isAdminView, ldap, openQRModal, openEditModal, deleteLink } = props

  const copyLink = useCallback(
    async url => {
      await navigator.clipboard.writeText(url)
      message.success(
        intl.formatMessage({ id: 'message.success.copiedLink' }, { url })
      )
    },
    [intl]
  )

  const deleteLinkCallBack = useCallback(async id => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'deleteLinkModal.title' }),
      content: intl.formatMessage({ id: 'deleteLinkModal.content' }),
      okText: intl.formatMessage({ id: 'deleteLinkModal.okText' }),
      cancelText: intl.formatMessage({ id: 'deleteLinkModal.cancelText' }),
      onOk: () => deleteLink(id)
    })
  }, [intl, deleteLink])

  const renderActions = useCallback(
    row => {
      return (
        <Space>
          <Tooltip
            title={intl.formatMessage({ id: 'tooltip.qrCode' })}
            placement={'bottom'}>
            <Button type={'text'} icon={<QrcodeOutlined />} onClick={() => openQRModal(row)} />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: 'tooltip.editLink' })}
            placement={'bottom'}>
            <Button type={'text'} icon={<EditOutlined />} onClick={() => openEditModal(row)} />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: 'tooltip.copyLink' })}
            placement={'bottom'}>
            <Button
              type={'text'}
              icon={<CopyOutlined />}
              onClick={() => copyLink(row.shortUrl)}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: 'tooltip.deleteLink' })}
            placement={'bottom'}>
            <Button type={'text'} danger icon={<DeleteOutlined />} onClick={() => deleteLinkCallBack(row.id)} />
          </Tooltip>
        </Space>
      )
    },
    [intl, copyLink, openQRModal, openEditModal, deleteLinkCallBack]
  )

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({ id: 'table.column.url' }),
        key: 'url',
        width: 300,
        render: (_, row) => (
          <>
            <Tooltip title={row.longUrl} placement={'topLeft'}>
              <Space>
                <GlobalOutlined />
                <Link href={row.shortUrl}>{row.shortFancyUrl}</Link>
              </Space>
            </Tooltip>
          </>
        )
      },
      {
        title: intl.formatMessage({ id: 'table.column.description' }),
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
        render: description => description || '-'
      },
      {
        title: intl.formatMessage({ id: 'table.column.type' }),
        dataIndex: 'type',
        key: 'type',
        width: 150,
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: type => (
          <Tag color={linkTypesColors[type]}>
            <FormattedMessage id={`linkType.${type}`} />
          </Tag>
        )
      },
      {
        title: intl.formatMessage({ id: 'table.column.clickCount' }),
        dataIndex: 'clickCount',
        key: 'clickCount',
        width: 90,
        sorter: (a, b) => a.clickCount - b.clickCount
      },
      {
        title: intl.formatMessage({ id: 'table.column.validFrom' }),
        dataIndex: 'validFrom',
        key: 'validFrom',
        width: 100,
        sorter: (a, b) => a.validFrom.localeCompare(b.validFrom),
        render: validFrom => validFrom || '-'
      },
      {
        title: intl.formatMessage({ id: 'table.column.expireAt' }),
        dataIndex: 'expireAt',
        key: 'expireAt',
        width: 120,
        sorter: (a, b) => `${a.expireAt || '-'}`.localeCompare(`${b.expireAt || '-'}`),
        render: expireAt => expireAt || '-'
      },
      isAdminView
        ? {
          title: intl.formatMessage({ id: 'table.column.owner' }),
          dataIndex: 'username',
          key: 'username',
          width: 120,
          sorter: (a, b) => `${a.username || '-'}`.localeCompare(`${b.username || '-'}`),
          render: username =>
            username ? (
              <Tag color={username === ldap ? 'green' : 'default'}>
                {username}
              </Tag>
            ) : (
              '-'
            )
        }
        : { width: 0 },
      {
        width: 200,
        render: (_, row) => renderActions(row)
      }
    ]
  }, [intl, isAdminView, ldap, renderActions])

  return (
    <Table
      size={'small'}
      dataSource={data}
      columns={columns}
      rowKey={row => row.id}
      pagination={{ showSizeChanger: false }}
      loading={{
        spinning: isLoading,
        indicator: <Loader />
      }}
      locale={{
        triggerAsc: intl.formatMessage({ id: 'table.sortAsc' }),
        triggerDesc: intl.formatMessage({ id: 'table.sortDesc' }),
        cancelSort: intl.formatMessage({ id: 'table.cancelSort' }),
        emptyText: (
          <Empty description={intl.formatMessage({ id: 'table.emptyText' })} />
        )
      }}
    />
  )
}

export default injectIntl(LinksTable)
