import { CREATE_LINK, DELETE_LINK, GET_LINKS, UPDATE_LINK } from '../action-types'
import api from 'src/common/api'
import { handleServerError, momentToStringDate } from 'src/common/utils'

export const getLinks = q => async dispatch => {
  try {
    const res = await api.links.list(q || undefined)
    dispatch({ type: GET_LINKS })
    return res.status === 204 ? [] : res.data.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const createLink = row => async dispatch => {
  try {
    const body = {
      ...row,
      validFrom: row.validFrom && momentToStringDate(row.validFrom),
      expireAt: row.expireAt && momentToStringDate(row.expireAt)
    }
    const res = await api.links.create(body)
    dispatch({ type: CREATE_LINK })
    return { status: res.status }
  } catch (error) {
    // Errore 406 -> hash duplicato
    if (error.response?.status === 406) return { status: error.response?.status }
    return handleServerError(error)
  }
}

export const updateLink = row => async dispatch => {
  try {
    const body = {
      ...row,
      validFrom: row.validFrom && momentToStringDate(row.validFrom),
      expireAt: row.expireAt && momentToStringDate(row.expireAt)
    }
    const res = await api.links.update(row.id, body)
    dispatch({ type: UPDATE_LINK })
    return { status: res.status }
  } catch (error) {
    // Errore 406 -> hash duplicato
    if (error.response?.status === 406) return { status: error.response?.status }
    return handleServerError(error)
  }
}

export const deleteLink = id => async dispatch => {
  try {
    const res = await api.links.delete(id)
    dispatch({ type: DELETE_LINK })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}
